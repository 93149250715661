import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  setLang = 'en';
  currentLang = {};

  languages = [
    {name: 'English', value: 'en'},
    {name: 'Español', value: 'es'},
    // {name: 'Português', value: 'pt'}
  ];


  langs = {
    en: {
      auth: {
        login: {
          email: 'Email',
          email_required: 'Email is required',
          email_valid: 'Please enter a valid email address',
          password: 'Password',
          password_required: 'Password is required',
          password_forgot: 'Forgot your password?',
          login: 'Login',
          create_account: 'Create an account'
        }
      },
      'system-admin': {
        'system-dashboard': {
          fires: 'Fires',
          cameras: 'Cameras',
          weather: 'Weather',
          lightning: 'Lightning',
          settings: 'Settings',
          manage_users: 'Manage Users',
          sign_out: 'Sign Out'
        }
      },
      cameras: {
        view: {
          select_camera: 'Select a camera',
          play: 'Play'
        }
      },
      fires: {
        view: {},
        details: {
          fire: 'Fire',
          logged: 'Logged',
          online: 'Online',
          detection: 'Detection',
          current: 'Current',
          forecast: 'Forecast',
          overlay: 'Day Overlay',
          id: 'Fire ID',
          alarm: 'Alarm',
          time: 'Time',
          lat: 'Lat',
          lon: 'Lon',
          logs: 'Logs',
          bearing: 'Bearing',
          range: 'Range',
          responses: 'Quick Responses 1',
          response: {
            responding: 'Responding 1',
            on_scene: 'On Scene 1',
            contained: 'Contained 1',
            mop_up: 'Mop Up 1',
            guarding: 'Guarding 1',
            leaving: 'Leaving Scene 1'
          }
        }
      },
      settings: {
        notifications: 'Notifications',
        fires: 'Fires',
        fire_update: 'Fire Update',
        weather: 'Weather',
        lightning: 'Lightning',
        admin: 'Admin',
        admin_view: 'Admin View',
        low_data: 'Low Data Mode',
        mapping: 'Mapping',
        request: 'Request System Access'
      },
      mapping: {
        draw_fire_fence: 'Draw Fire Fence'
      }
    },
    es: {
      auth: {
        login: {
          email: 'ES Email',
          email_required: 'ES Email is required',
          email_valid: 'ES Please enter a valid email address',
          password: 'ES Password',
          password_required: 'ES Password is required',
          password_forgot: 'ES Forgot your password?',
          login: 'ES Login',
          create_account: 'ES Create an account'
        }
      },
      'system-admin': {
        'system-dashboard': {
          fires: 'ES Fires',
          cameras: 'ES Cameras',
          weather: 'ES Weather',
          lightning: 'ES Lightning',
          settings: 'ES Settings',
          manage_users: 'ES Manage Users',
          sign_out: 'ES Sign Out'
        }
      },
      cameras: {
        view: {
          select_camera: 'ES Select a camera',
          play: 'ES Play'
        }
      },
      fires: {
        view: {},
        details: {
          fire: 'ES Fire',
          logged: 'ES Logged',
          online: 'ES Online',
          detection: 'ES Detection',
          current: 'ES Current',
          forecast: 'ES Forecast',
          overlay: 'ES Day Overlay',
          id: 'ES Fire ID',
          alarm: 'ES Alarm',
          time: 'ES Time',
          lat: 'ES Lat',
          lon: 'ES Lon',
          logs: 'ES Logs',
          bearing: 'ES Bearing',
          range: 'ES Range',
          responses: 'ES Quick Responses',
          response: {
            responding: 'ES Responding',
            on_scene: 'ES On Scene',
            contained: 'ES Contained',
            mop_up: 'ES Mop Up',
            guarding: 'ES Guarding',
            leaving: 'ES Leaving Scene'
          }
        }
      },
      settings: {
        notifications: 'ES Notifications',
        fires: 'ES Fires',
        fire_update: 'ES Fire Update',
        weather: 'ES Weather',
        lightning: 'ES Lightning',
        admin: 'ES Admin',
        admin_view: 'ES Admin View',
        low_data: 'ES Low Data Mode',
        mapping: 'ES Mapping',
        request: 'ES Request System Access'
      },
      mapping: {
        draw_fire_fence: 'ES Draw Fire Fence'
      }
    },
  };

  constructor() {
    this.setLang = 'en';
    this.currentLang = this.langs[this.setLang];
    this.get('auth.login.email');
  }

  changeLang(lang: string) {
    this.setLang = lang;
    this.currentLang = this.langs[lang];
  }

  get(key: string) {
    const keys = key.split('.');
    let value = this.currentLang;
    for (const k of keys) {
      value = value[k];
    }
    return value;
  }
}
