import {Component, OnInit} from '@angular/core';
import {NotificationsService} from './core/services/notifications.service';
import {MappingService} from './core/services/mapping.service';
import {DeviceService} from './core/services/device.service';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loginUserDetail: any;
  private lang;

  constructor(
    public mapService: MappingService,
    public deviceService: DeviceService,
    public translate: TranslateService,
    private platform: Platform,
  ) {
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {

      this.mapService.getCurrentPosition();
      this.translate.addLangs(['en', 'es']);
      this.translate.setDefaultLang('en');

      this.lang = this.deviceService.getLanguage().then(lang => {
        // console.log('lang', lang);
        this.translate.use(lang.value);
      });

    });
  }

  ngOnInit(): void {
  }
}
