// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  americas: {
    apiKey: 'AIzaSyCA-xaSVeBHoKI1poLIYyWqdwenHzNSSyA',
    authDomain: 'firehawk-web.firebaseapp.com',
    databaseURL: 'https://firehawk-v2-user-reports.firebaseio.com',
    projectId: 'firehawk-web',
    storageBucket: 'firehawk-web.appspot.com',
    messagingSenderId: '881204731785',
    appId: '1:881204731785:web:bc736ef0b931c1e31c0b58'
  },
  africaAndEast: {
    apiKey: 'AIzaSyAQEC8guoyPplhDtRsYStM6BhVB4z3dXJc',
    authDomain: 'firehawk-v2.firebaseapp.com',
    databaseURL: 'https://firehawk-v2-user-reports.firebaseio.com',
    databaseURLs:
      {
        "user-reports": 'https://firehawk-v2-user-reports.europe-west1.firebasedatabase.app',
        "weather": 'https://firehawk-v2-weather.europe-west1.firebasedatabase.app',
        "voltages": 'https://firehawk-v2-voltages.europe-west1.firebasedatabase.app',
        "fires": 'https://firehawk-v2-fires.europe-west1.firebasedatabase.app'
      },
    projectId: 'firehawk-v2',
    storageBucket: 'firehawk-v2.appspot.com',
    messagingSenderId: '1026236827150',
    appId: '1:1026236827150:web:dc7e9e248ac86cf05ede5f',
    measurementId: 'G-3NFM6YDCED'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
