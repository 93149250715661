import {Injectable} from '@angular/core';
import {Device} from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() {
    const logDeviceInfo = async () => {
      const info = await Device.getInfo();

      // console.log(info);
    };

    const logBatteryInfo = async () => {
      const info = await Device.getBatteryInfo();

      // console.log(info);
    };
  }

  getLanguage() {
    return Device.getLanguageCode();
  }
}
