import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from 'src/environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {TranslatePipe} from './core/pipes/translate.pipe';
import { DisponiblePipePipe } from './core/pipes/disponible-pipe.pipe';


import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { PadNumberPipePipe } from './core/pipes/pad-number-pipe.pipe';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [AppComponent, TranslatePipe, DisponiblePipePipe, PadNumberPipePipe],
    bootstrap: [AppComponent],
    exports: [
        TranslatePipe,
        PadNumberPipePipe
    ], imports: [
        BrowserModule,
        PinchZoomModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp((localStorage.getItem('environment') === 'true') ? environment.americas : environment.africaAndEast),
        AngularFireAuthModule,
        AngularFirestoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
