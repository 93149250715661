import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.authService.getStorage().then((res) => {
      const user = res;
      if (user) {
        if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
          if (user.role == 0) {
            this.router.navigateByUrl('/admin');
          } else if (user.role == 1) {
            this.router.navigateByUrl('/system-admin');
          } else if (user.role == 2) {
            this.router.navigateByUrl('/user');
          }

          return false;
        }

        return true;
      }
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    });
  }
}
