import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '../services/translate.service';

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {

  constructor(
    public translator: TranslateService
  ) {
  }

  transform(value: string, ...args: unknown[]): unknown {
    return this.translator.get(value);
  }

}
