import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  signOut,
} from 'firebase/auth';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Preferences} from '@capacitor/preferences';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public status: string = '';

  lastLoggedInUser: User;
  loginUserDetail: User;
  mobileDevice = false;
  isLastLoggedInUser = false;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
  }

  public hasSystems(): boolean {
    if (this.loginUserDetail) {
      if (this.loginUserDetail.approvedRequests) {
        if (this.loginUserDetail.approvedRequests.length > 0) {
          return true;
        }
      }
      if (this.loginUserDetail.assignedSystems) {
        if (this.loginUserDetail.assignedSystems.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  async getStorage() {
    return await Preferences.get({key: 'userdetail'}).then((res) => JSON.parse(res.value));
  }

  createUserWithEmailAndPassword(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  deleteAccount() {
    this.afAuth.currentUser.then((user) => {
      // delete user from firebase
      user.delete().then(() => {
        // delete user from firestore
        this.removeDataFromCollection('users', this.loginUserDetail.id).then(() => {
          this.signOut().then(r => {
            alert('Account deleted successfully');
          });
        });
      });
    });
  }

  getLoginUserDetail(collectionName: any, id: any) {
    return this.afs
      .collection(collectionName, (ref) => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map((params: any) => {
          const data = this.convertSnaps(params);
          return data;
        })
      );
  }

  getUserDetailFromEmail(collectionName: any, email: any) {
    return this.afs
      .collection(collectionName, (ref) => ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(
        map((params: any) => {
          const data = this.convertSnaps(params);
          return data;
        })
      );
  }

  removeDataFromCollection(collectionName: any, id: any) {
    return this.afs.collection(collectionName).doc(id).delete();
  }

  convertSnaps<T>(snaps) {
    return snaps.map((snap) => ({
        id: snap.payload.doc.id,
        ...snap.payload.doc.data(),
      }));
  }

  createCollection(collectionName: any, id: any, data: any) {
    return this.afs.collection(collectionName).doc(id).set(data);
  }

  addIdinCollection(collectionName: any, id: any) {
    return this.afs
      .collection(collectionName)
      .doc(id)
      .set({id}, {merge: true});
  }

  signInWithEmailAndPassword(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signUpWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    return signInWithPopup(auth, provider);
  }

  signUpWithApple() {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');

    return signInWithPopup(auth, provider);
  }

  sendEmailLink(email) {
    const auth = getAuth();
    const actionCodeSettings = {
      url: 'http://localhost:8100/auth/confirm-signin',
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  }

  signOut() {
    Preferences.clear();
    this.currentUserSubject = new BehaviorSubject<any>('');
    this.currentUser = this.currentUserSubject.asObservable();
    this.loginUserDetail = null;
    try {
      const auth = getAuth();
      return signOut(auth)
        .then(() => {
          localStorage.clear();
          this.router.navigateByUrl('/auth/login');
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      this.router.navigateByUrl('/auth/login').then(() => {
        localStorage.clear();
        window.location.reload();
        console.log('Logged out');
      });
    }
  }

  updateUser(loginUserDetail: User) {
    this.afs
      .collection('users')
      .doc(loginUserDetail.id)
      .set(loginUserDetail, {merge: true})
      .then(() => {
        this.currentUserSubject = new BehaviorSubject<any>(loginUserDetail);
        this.currentUser = this.currentUserSubject.asObservable();
        Preferences.set({
          key: 'userdetail',
          value: JSON.stringify(loginUserDetail),
        });
      });
  }

  updateDashboard(id, userId) {
    this.afs
      .collection('dashboards')
      .doc(id)
      .set({
        userId,
      }, {merge: true}).then(r => {
        console.log('Logged in');
    }).catch(err => {
        console.log(err);
    });
  }

  isDataManager() {
    try {
      return this.loginUserDetail.dataManager;
    } catch (err) {
      return false;
    }
  }

  isAdmin() {
    try {
      return this.loginUserDetail.admin;
    } catch (err) {
      return false;
    }
  }

  async logInAsUser(userId: string) {
    const res = {
      user: {
        uid: userId,
      }
    };
    this.lastLoggedInUser = this.loginUserDetail;

    await Preferences.set({
      key: 'lastLoggedInUser',
      value: JSON.stringify(this.loginUserDetail),
    });

    this.getLoginUserDetail('users', res.user.uid).subscribe(
      (detail) => {

        Preferences.set({
          key: 'userdetail',
          value: JSON.stringify(detail[0]),
        });

        this
          .getStorage()
          .then((val) => {
            this.currentUserSubject = new BehaviorSubject<any>(
              val
            );
            this.currentUser =
              this.currentUserSubject.asObservable();
          })
          .catch((err) => {
            const error = err.message.replace('Firebase:', '');
          });

        if (detail.length) {
          if (detail[0].role === 0) {
            this.router.navigateByUrl('/admin');
          } else if (detail[0].role === 2) {
            this.router.navigateByUrl('/user');
          } else if (detail[0].role === 1) {
            this.router.navigateByUrl('/user-manager');
          }
        }

      },
      (err) => {
        console.log(err);
      }

    );

    this.isLastLoggedInUser = await this.checkLastLoggedInUser()

  }


  async checkLastLoggedInUser(): Promise<boolean> {
    try {
      const lastUser = await Preferences.get({ key: 'lastLoggedInUser' });
      console.log(lastUser);
      // returns true if lastUser.value exists, false otherwise
      return !!lastUser && !!lastUser.value;
    } catch (err) {
      console.error(err);
      return false;
    }
  }


  async fetchLastLoggedInUserUid(): Promise<string | null> {
    try {
      const lastUser = await Preferences.get({ key: 'lastLoggedInUser' });
      if (lastUser) {
        const userDetail = JSON.parse(lastUser.value);
        return userDetail.id;
      }
      return null;  // return null if there is no last logged in user
    } catch (err) {
      console.error(err);
      this.isLastLoggedInUser = false;
      return null;
    }
  }

  async logInAsLastUser() {
    try {
      const uid = await this.fetchLastLoggedInUserUid();
      if (uid) {
        await this.logInAsUser(uid);
        await Preferences.remove({ key: 'lastLoggedInUser' });
        this.isLastLoggedInUser = await this.checkLastLoggedInUser()
      }
    } catch (err) {
      console.error(err);
    }

  }


}
