import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./user/user.module').then((m) => m.UserPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.System_Admin] },
  },
  {
    path: 'system-admin',
    loadChildren: () =>
      import('./system-admin/system-admin.module').then(
        (m) => m.SystemAdminPageModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.System_Admin] },
  },
  {
    path: 'cameras',
    loadChildren: () => import('./cameras/cameras.module').then(m => m.CamerasPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'weather',
    loadChildren: () => import('./weather/weather.module').then( m => m.WeatherPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'fires',
    loadChildren: () => import('./fires/fires.module').then( m => m.FiresPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'lightning',
    loadChildren: () => import('./lightning/lightning.module').then( m => m.LightningPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'mapping',
    loadChildren: () => import('./mapping/mapping.module').then( m => m.MappingPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'overview',
    loadChildren: () => import('./overview/overview.module').then( m => m.OverviewPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'dispatch',
    loadChildren: () => import('./dispatch/dispatch.module').then( m => m.DispatchPageModule),
    canActivate: [AuthGuard],
    data: {roles: [Role.User, Role.Admin, Role.System_Admin]}
  },
  {
    path: 'no-access-page',
    loadChildren: () => import('./shared/no-access-page/no-access-page.module').then( m => m.NoAccessPagePageModule)
  },
  {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then( m => m.OperationsPageModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./general/general.module').then( m => m.GeneralPageModule)
  },
  {
    path: 'clusters',
    loadChildren: () => import('./clusters/clusters.module').then( m => m.ClustersPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
