import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Geolocation} from '@capacitor/geolocation';
import {Preferences} from "@capacitor/preferences";
import {saveAs} from 'file-saver';
import {Fire} from "../models/fire.model";
import {Platform} from "@ionic/angular";
import {Camera} from "../models/camera.model";
import * as localforage from "localforage";
import {headingTo} from "geolocation-utils";

@Injectable({
  providedIn: 'root'
})
export class MappingService {
  declination: number | null = 17.5;

  stylesDark = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];
  stylesLight = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];
  styles = [];

  mapType = 'satellite';
  mapPolys = [];

  currentPosition;
  center = {lat: 0, lng: 0};
  hoveredPlantation = null;
  hoverX = 0;
  hoverY = 0;

  options: google.maps.MapOptions = {
    zoom: 3,
    zoomControl: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 3,
    mapTypeControl: true,
    clickableIcons: true,
    streetViewControl: false,
  };

  personMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/user_location.svg',
      // anchor: new google.maps.Point(16, 16),
    },
  }

  fireMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/fire.png',
      // anchor: new google.maps.Point(23, 23),
    },
    clickable: true,
  };

  fireMarkerOptions24: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/fire_red.png',
    },
    clickable: true,
  };

  fireMarkerOptions48: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/fire_orange.png',
    },
    clickable: true,
  };

  fireMarkerOptions72: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/fire_grey.png',
    },
    clickable: true,
  };

  fireMarkerOptions1week: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/fire_red.png',
      // scaledSize: new google.maps.Size(32, 32),
      // size: new google.maps.Size(32, 32),
    },
    clickable: true,
  };

  fireMarkerOptions1month: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/FireBlueSelected.svg',
      // scaledSize: new google.maps.Size(32, 32),
      // size: new google.maps.Size(32, 32),
    },
    clickable: true,
  };

  towerMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/icons/Tower.png',
      // anchor: new google.maps.Point(16, 16),
    },
  };

  weatherMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/img/weatherstation.png',
    }
  };


  xrefOptions: google.maps.PolygonOptions = {
    strokeColor: '#e6873e',
    strokeOpacity: 0.85,
    strokeWeight: 2,
    fillColor: '#000000',
    fillOpacity: 1,
    clickable: false
  }

  fireWedge: number[][];

  mappingData = [];
  viewshedData = [];

  wedgePaths: { lng: number; lat: number }[];
  wedgeComplete: boolean;

  wedgeOptions: google.maps.PolygonOptions = {
    strokeColor: '#e6873e',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#000000',
    fillOpacity: 0.35,
    clickable: false,
  }

  viewshedOptions: google.maps.KmlLayerOptions = {
    clickable: false,
    preserveViewport: true,
  }

  presetNumber: any;
  clickLoc: any[];
  lastBearing: number;
  xreflist = [];
  zoom = 9;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.getCurrentPosition();

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (prefersDark) {
        this.styles = this.stylesDark;
      } else {
        this.styles = this.stylesLight;
      }

    });
  }

  clearHoveredPlantation() {
    this.hoveredPlantation = null;
  }

  setHoverPlantation(label: object, event) {
    // console.log(event);
    this.hoveredPlantation = label;
    this.hoverX = event.domEvent.pageX;
    this.hoverY = event.domEvent.pageY;
  }

  watchPosition() {
    const wait = Geolocation.watchPosition({}, (position, err) => {
      if (err) {
        // console.log(err);
        return;
      } else {
        this.currentPosition = position.coords;
      }
      // console.log(position);
    });
  }

  getCurrentPosition() {
    const wait = Geolocation.getCurrentPosition().then((position) => {
      this.currentPosition = position.coords;
      // console.log(position.coords);
    });
  }


  getMapJson(allMaps: any[]) {

    if (!allMaps){
      return
    }

    this.mappingData = [];
    // console.log('fetching maps')
    // console.log(allMaps);
    for (const map of allMaps) {
      this.downloadMap(map);
    }
    // console.clear();
    return this.mappingData;
  }

  async getMap(key) {
    return await Preferences.get({key}).then((res) => JSON.parse(res.value));
  }

  async saveMap(key, value) {
    return await Preferences.set({key, value: JSON.stringify(value)});
  }

  async checkMap(key) {
    return await Preferences.get({key}).then((res) => !!res.value);
  }

  async clearLocalMap() {
    const allMaps = this.authService.loginUserDetail.geoJson;
    for (const map of allMaps) {
      await Preferences.remove({key: map.name});
    }
  }

  mapLinkUrl(lat, lng) {
    if (this.platform.is('android')) {
      return 'https://maps.google.com/maps?q=loc:' + lat + ',' + lng + '&navigate=yes';
    } else if (this.platform.is('ios')) {
      return 'https://maps.google.com/maps?q=' + lat + ',' + lng + '&navigate=yes';
    }
  }

  downloadKml(fire: Fire) {
    const kmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Placemark>
    <name>${fire.id}</name>
    <description>
      <![CDATA[
        <img src="${fire.image_data.branded_url}" width="600px" alt="Fire image">
      ]]>
    </description>
    <Style>
      <IconStyle>
        <Icon>
          <href>https://firehawk-v2.web.app/assets/img/fire.png</href>
        </Icon>
      </IconStyle>
    </Style>
    <Point>
      <coordinates>${fire.fire.lon},${fire.fire.lat},0</coordinates>
    </Point>
  </Placemark>
</kml>`;

    const blob = new Blob([kmlContent], {type: 'application/vnd.google-earth.kml+xml'});
    saveAs(blob, `${fire.id}.kml`);
  }

  public clearCachedMaps(): Promise<void> {
    return new Promise((resolve, reject) => {
      localforage.clear().then(() => {
        this.mappingData = [];
        console.log('All map data has been cleared from cache.');
        resolve();
      }).catch((error) => {
        console.error('Failed to clear map data from cache:', error);
        reject(error);
      });
    });
  }

  private downloadMap(map): Promise<any> {
    return new Promise((resolve, reject) => {
      localforage.getItem(map.name).then((cachedData) => {
        if (cachedData) {
          this.handleGeoJsonData(cachedData, map);
        } else {
          // console.log('Downloading map data.');
          this.http.get(map.url).subscribe(
            data => {
              this.handleGeoJsonData(data, map);
              localforage.setItem(map.name, data)
                .then(() => console.log('Map data cached successfully.'))
                .catch(err => console.error('Failed to cache map data:', err));
            },
            error => {
              reject(error); // Reject the promise with the error
            }
          );
        }
      }).catch((error) => {
        console.error('Error while retrieving cached map data:', error);
        reject(error);
      });
    });
  }


  private handleGeoJsonData(data: any, map) {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    for (const feature of data.features) {
      if (feature.geometry.type === 'Polygon') {
        this.swapCoordinates(feature.geometry.coordinates, map, feature.properties);
      } else if (feature.geometry.type === 'MultiPolygon') {
        for (const polygon of feature.geometry.coordinates) {
          this.swapCoordinates(polygon, map, feature.properties);
        }
      } else {
        console.log(feature.geometry.type);
      }
    }
  }

  private swapCoordinates(coordsArray, map, properties) {
    const data = {
      paths: [],
      options: {
        strokeColor: map.color,
        strokeOpacity: 0,
        strokeWeight: 0,
        fillColor: map.color,
        fillOpacity: 0.5,
        label: properties.name
      },
      properties: properties
    };

    if (map.fromSHP) {
      for (const polygon of coordsArray) {
        for (const coordinate of polygon) {
          // console.log(coordinate);
          data.paths.push({
            lat: coordinate[0],
            lng: coordinate[1]
          });
        }
      }
    } else {
      for (const polygon of coordsArray) {
        for (const coordinate of polygon) {
          // console.log(coordinate);
          data.paths.push({
            lat: coordinate[1],
            lng: coordinate[0]
          });
        }
      }
    }

    data.paths.push(data.paths[0])

    this.mappingData.push(data);
    return data;
  }

  public toRad(degrees) {
    const pi = Math.PI;
    return degrees * (pi / 180);
  }

  public toDeg(degrees) {
    const pi = Math.PI;
    return degrees * 180 / Math.PI;
  }

  toNumber(words) {
    return parseFloat(words);
  }

  pointFromStart(dist, brng, lat, lng) {
    dist = dist / 6371;
    brng = this.toRad(brng);

    const lat1 = this.toRad(lat);
    const lon1 = this.toRad(lng);

    const lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) +
      Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

    const lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist) *
      Math.cos(lat1),
      Math.cos(dist) - Math.sin(lat1) *
      Math.sin(lat2));

    if (isNaN(lat2) || isNaN(lon2)) {
      return null;
    }

    return [this.toDeg(lat2), this.toDeg(lon2)];
  }

  distanceBetweenTwoPoints(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    // console.log('Distance: ', d);

    return d;
  }

  generateFireWedge(fire: Fire, imgSize = 1920) {
    this.wedgeComplete = false;

    const pct = fire.image_data.position.x / imgSize;
    const cntr = imgSize / 2;
    const ofst = 30 * (pct - 0.5);

    // console.log(fire.xpos, pct, ofst);

    // tslint:disable-next-line:radix
    const bearing = fire.fire.bearing;

    const t_lat = fire.tower.lat;
    const t_long = fire.tower.lon;

    const distance = this.distanceBetweenTwoPoints(t_lat, t_long, fire.fire.lat, fire.fire.lon);

    const points = [[t_lat, t_long]];
    this.wedgePaths = [{lat: t_lat, lng: t_long}];

    const range = [-15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1,
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    // tslint:disable-next-line:forin
    for (const k in range) {
      // console.log(range[k]);
      const brng = bearing + range[k] - ofst;
      // console.log('Bearing Calcs: ', bearing, range[k], ofst, brng);
      const pnt = this.pointFromStart(distance + 1, brng, t_lat, t_long);
      points.push(pnt);
      this.wedgePaths.push({lat: pnt[0], lng: pnt[1]});
    }

    points.push([t_lat, t_long]);
    this.wedgePaths.push({lat: t_lat, lng: t_long});

    this.fireWedge = points;
    // console.log(points);
    this.wedgeComplete = true;
    return points;
  }

  position($event: any, tower: Camera) {
    const presetNumber = this.presetNumber;
    console.clear();
    // console.log($event, tower)

    const scaledImage = [$event.target.clientWidth, $event.target.clientHeight];
    const clickLoc = [$event.layerX, $event.layerY];
    this.clickLoc = [$event.layerX, $event.layerY];

    const x = clickLoc[0] / scaledImage[0];

    const numPresets = 12;
    const degrees = 360 / 12;
    const right = (degrees * presetNumber) - (degrees * 0.5);
    const left = (degrees * presetNumber) - (degrees * 1.5);
    const center = (degrees * presetNumber) - (degrees);

    let posx = left + (x * degrees);
    const destinationPoint = this.pointFromStart(
      30,
      posx,
      tower.lat,
      tower.lng
    );
    if (posx < 0) {
      posx = posx + 360;
    }
    this.lastBearing = Math.round(posx);

    this.xreflist[tower.name] = [
        {lat: this.toNumber(tower.lat), lng: this.toNumber(tower.lng)},
        {lat: destinationPoint[0], lng: destinationPoint[1]},
        {lat: this.toNumber(tower.lat), lng: this.toNumber(tower.lng)}
      ];
    // console.log(this.xreflist);
    // console.log(this.wedgePaths);
  }

  generateCameraWedge(tower: any, presetNumber: any) {
    this.wedgeComplete = false;
    this.presetNumber = presetNumber;

    const degrees = 360 / 12;
    const left = (degrees * presetNumber) - (degrees * 1.5);
    const right = (degrees * presetNumber) - (degrees * 0.5);
    const center = (degrees * presetNumber) - (degrees);

    // tslint:disable-next-line:radix
    const bearing = parseInt(String(center));

    const t_lat = parseFloat(tower.lat);
    const t_long = parseFloat(tower.lng);

    const distance = 12;
    const points = [[t_lat, t_long]];
    this.wedgePaths = [{lat: t_lat, lng: t_long}];

    const range = [-15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1,
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    // tslint:disable-next-line:forin
    for (const k in range) {
      // console.log(range[k]);
      const brng = bearing + range[k];
      // console.log('Bearing Calcs: ', bearing, range[k], ofst, brng);
      const pnt = this.pointFromStart(distance + 1, brng, t_lat, t_long);
      points.push(pnt);
      this.wedgePaths.push({lat: pnt[0], lng: pnt[1]});
    }

    points.push([t_lat, t_long]);
    this.wedgePaths.push({lat: t_lat, lng: t_long});

    this.fireWedge = points;
    // console.log(this.wedgePaths);
    this.wedgeComplete = true;
    return points;
  }

  isXrefActive() {
    return Object.keys(this.xreflist).length > 0;
  }

  viewShape(map: any) {
    console.clear();
    console.log(map);
  }

  getViewshedFromLink(url: string) {
    this.http.get(url).subscribe((data: any) => {
      // console.log(data);

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      for (const feature of data.features) {
        if (feature.geometry.type === 'Polygon') {
          this.swapViewShedCoordinates(feature.geometry.coordinates, feature.properties);
        } else if (feature.geometry.type === 'MultiPolygon') {
          for (const polygon of feature.geometry.coordinates) {
            this.swapViewShedCoordinates(polygon, feature.properties);
          }
        } else {
          console.log(feature.geometry.type);
        }
      }

    });
  }

  swapViewShedCoordinates(coordsArray: any, properties: any) {
    const data = {
      paths: [],
      options: this.viewshedOptions,
      properties: properties
    };

    for (const polygon of coordsArray) {
      for (const coordinate of polygon) {
        // console.log(coordinate);
        data.paths.push({
          lat: coordinate[1],
          lng: coordinate[0]
        });
      }
    }

    this.viewshedData.push(data);
    // console.log(data);
  }

  getCenter(fires) {
    // for each fire in the results array, get the lat and lng and push it to the center array
    let topLat = -99;
    let bottomLat = 99;
    let leftLng = 180;
    let rightLng = -180;
    fires.forEach((fire) => {
      // console.log(fire.fire);
      if (fire.fire.lat > topLat) {
        topLat = fire.fire.lat;
      }
      if (fire.fire.lat < bottomLat) {
        bottomLat = fire.fire.lat;
      }
      if (fire.fire.lon > rightLng) {
        rightLng = fire.fire.lon;
      }
      if (fire.fire.lon < leftLng) {
        leftLng = fire.fire.lon;
      }
    });
    // get the center of the map
    const centerLat = (topLat + bottomLat) / 2;
    const centerLng = (rightLng + leftLng) / 2;
    this.center = {lat: centerLat, lng: centerLng};
    if (this.currentPosition) {
      this.center = {lat: this.currentPosition.latitude, lng: this.currentPosition.longitude};
    }
    this.zoom = 12;
  }

  calculateDistance(latitude: number, longitude: number, latitude2: number, longitude2: number) {
    // console.log("distance", latitude, longitude, latitude2, longitude2);

    // make sure the values are numbers and are defined
    if (isNaN(latitude) || isNaN(longitude) || isNaN(latitude2) || isNaN(longitude2)) {
      return -1;
    }

    const R = 6371e3; // metres
    const φ1 = this.toRadians(latitude);
    const φ2 = this.toRadians(latitude2);
    const Δφ = this.toRadians(latitude2 - latitude);
    const Δλ = this.toRadians(longitude2 - longitude);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c;
    return this.round(d / 1000, 0);
  }

  toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  calculateBearing(φ1: number, λ1: number, φ2: number, λ2: number, declination: number = 0) {
    // console.log("Bearing", φ1, λ1, φ2, λ2);

    // make sure the values are numbers and are defined
    if (isNaN(φ1) || isNaN(λ1) || isNaN(φ2) || isNaN(λ2)) {
      return -1;
    }

    let heading = headingTo(
      { lat: φ1, lon: λ1 },
      { lat: φ2, lon: λ2 }
    );
    if (heading < 0) {
      heading = 360 + heading;
    }

    heading = heading + declination;

    if (heading >= 360) {
      heading = heading - 360;
    } else if (heading < 0) {
      heading = heading + 360;
    }

    return this.round(heading, 0);
  }

  round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

}
